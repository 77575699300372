<template lang="pug">
  .confirmation-step
    .uo-header.header
      h2.nio-h2.text-primary-darker Charges and fees
    NioChargeSummary(
      :line-items="chargeLineItems"
      rebilling-message="Subscriptions renew automatically at the start of every month and can be paused at any time."
    )
    .download.nio-p.text-primary-dark Your data will be available for download on the Onboarded Subscriptions page in 1-2 hours.
</template>

<script>

export default {
  props: {
    "completedSummary": { type: Object, required: false },
    "budgetData": { type: Object, required: false }
  },
  data: () => ({
    chargeLineItems: []
  }),
  watch: {
    budgetData(val) {
      if (val) {
        this.createLineItems()
      }
    }
  },
  mounted() {
    this.createLineItems()
    this.$emit('stepPayloadChanged', {})
  },
  methods: {
    createLineItems() {
      this.chargeLineItems = []
      this.chargeLineItems.push({ name: 'Monthly Budget', value: this.budgetData.budgetAmount })
    }
  }
};
</script>

<style lang="sass" scoped>
  .confirmation-step
    display: flex
    flex-direction: column
    align-items: center
    .header
      margin-bottom: 24px
      flex-direction: column
      align-items: center

    .download
      margin-top: 1.5rem
</style>