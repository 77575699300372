<template lang="pug">
  .destination-step
    .uo-header.header
      h2.nio-h2.text-primary-darker Choose where to send your data
      p.nio-p.text-primary-dark Our system will find ADIDs and IDFAs connected to your source data and deliver them to your Onboarded Subscriptions page for download.
    NioSummarySlat.summary(
      v-if="completedSummary"
      :title="completedSummary.title"
      :details-label="completedSummary.detailsLabel"
      :details-value="completedSummary.detailsValue"
      :details-annotation="completedSummary.detailsAnnotation"
      image-rc="https://cdn.narrative.io/images/data-stream/images/narrative-placeholder-normal.svg"
      active
    )  
</template>

<script>

export default {
  props: {
    "completedSummary": { type: Object, required: false }
  }
};
</script>

<style lang="sass" scoped>
  .destination-step
    .header
      margin-bottom: 24px
      flex-direction: column
      align-items: center
      h2
        margin-bottom: 8px
      p 
        text-align: center
    .summary
      margin: 0 auto 24px auto
</style>