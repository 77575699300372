<template lang="pug">
  .match-step
    .uo-header.header(v-if="!completedSummary")
      h2.nio-h2.text-primary-darker Next, let's find ID matches
      p.nio-p.text-primary-dark Match your customer data with mobile ad IDs from our data lake.
    .uo-header.header(v-else)
      h2.nio-h2.text-primary-darker Matches found
    NioSummarySlat.summary(
      v-if="!completedSummary"
      :loading="matching"
      :details-value="numDataLakeIds"
      image-src="https://cdn.narrative.io/images/data-stream/images/narrative-placeholder-normal.svg"
      title="Narrative Data Lake"
      details-label="Available to Match"
      loading-msg=""
      details-annotation="IDs for matching"
    )
      template(v-slot:loading v-if="matching")
        v-progress-circular.progress(
          size="20" 
          color="#1438F5"
          indeterminate
        )
    NioSummarySlat.summary.complete(
      v-else-if="completedSummary"
      :title="completedSummary.title"
      :details-label="completedSummary.detailsLabel"
      :details-value="completedSummary.detailsValue"
      :details-annotation="completedSummary.detailsAnnotation"
      image-src="https://cdn.narrative.io/images/data-stream/images/narrative-placeholder-normal.svg"
      active
    )  
    .match-error.nio-p.text-black(v-if="error") Hmm, we weren't able to find matches. <a href="https://kb.narrative.io/why-dont-i-have-matches-in-universal-onboarding" target="_blank" >Here</a> are some ways to improve your match rate, or contact us at <a href="mailto:support@narrative.io?Subject=Universal Onboarding match error" target="_blank" >support@narrative.io.</a>
    .actions
      NioButton(
        v-if="!error && !completedSummary && !matching"
        key="1"
        normal-secondary 
        @click="start"
      ) Start matching
      NioButton(
        v-if="matching"
        key="2"
        caution-outlined 
        @click="cancel") Cancel  
      NioButton(
        v-if="error"
        key="3"
        normal-secondary 
        @click="choooseAnotherSource"
      ) Choose another source
</template>

<script>
export default {
  props: {
    "completedSummary": { type: Object, required: false },
    "sourceStepData": { type: Object, required: false }
  },
  data: () => ({
    numDataLakeIds: '1.9 Billion',
    matching: false, 
    error: false
  }),	
  watch: {
    sourceStepData() {
      this.error = false
    }
  },
  methods: {
    start() {
      this.matching = true
      this.$nioOpenApi.post(
          `/lists/${this.sourceStepData.listId}/forecast`
        ).then(res => {
          const idTypes = ['adid', 'idfa']
          let matched = res.data.matched
          let totalCost = 0
          let totalAddressable = 0
          let totalDestinationIds = 0
          // Handle both old and new UO forecasting response formats to decouple UO app deployment from open-api
          // deployment.
          if (!Array.isArray(res.data.id_types)) {
            // v1 response format
            idTypes.forEach(key => {
              totalCost += res.data.id_types[key].cost.value
              totalAddressable += res.data.id_types[key].addressable
              totalDestinationIds += res.data.id_types[key].total
            })
          } else {
            // v2 response format
            res.data.id_types.forEach(summary => {
              if (idTypes.includes(summary.id_type)) {
                totalCost += summary.cost.value
                totalAddressable += summary.addressable
                totalDestinationIds += summary.pairs
              }
            })
          }
          if (matched > 0) {
            this.$emit('stepPayloadChanged', {
              numMatches: matched,
              numAddressable: totalAddressable,
              totalCost: totalCost,
              totalDestinationIds: totalDestinationIds,
              matchRate: this.sourceStepData && this.sourceStepData.numValidIds ? Math.round(100 * matched / this.sourceStepData.numValidIds) : null
            })
          } else {
            this.$emit('stepPayloadChanged', null)
            this.error = true
          }
        }).catch(err => {
          this.error = true
          console.log(err.response)
        }).finally(evt => {
          this.matching = false
        })
    },
    cancel() {
      this.matching = false
    },
    choooseAnotherSource() {
      this.$emit('deleteSource')
    }
  }
};
</script>

<style lang="sass" scoped>
  .match-step
    .header
      margin-bottom: 24px
      flex-direction: column
      align-items: center
      h2
        margin-bottom: 8px
    .summary
      margin: 0 auto 24px auto
      .v-progress-circular
     
    .match-error
      text-align: center
      width: 640px
      margin: -16px auto 24px auto
    .actions
      display: flex
      justify-content: center
      align-items: center
</style>
