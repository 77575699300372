<template lang="pug">
  .budget-step
    .uo-header.header
      h2.nio-h2.text-primary-darker Set your budget
      p.nio-p.text-primary-dark Set your monthly spending limit.
    NioBudgetOptions(
      v-if="budgetOptions.length"
      :forecast="forecast"
      :forecast-loading="forecastLoading"
      :custom-budget-min="100"
      :custom-budget="roundToNearest(matchData.totalCost, 100) >= 200"
      @customBudgetChanged="customBudgetChanged($event)"
      @customBudgetOpened="customBudgetOpened"
      @customBudgetClosed="customBudgetClosed"
      @customBudgetValidChanged="customBudgetValidChanged($event)"
    )
      NioBudgetOption(
        v-for="option of budgetOptions"
        :option="option"
        :selected="selectedOption && selectedOption.name === option.name"
        @selected="selectionChanged($event)"
      )
        template(v-slot:content)
          .nio-p.text-primary-dark Get up to
          .h3.text-primary-dark {{ formatForecast(option.forecast) }}
          .nio-p.text-primary-dark Deliverable IDs
</template>

<script>

import numeral from 'numeral'

export default {
  props: {
    "matchData": { type: Object, required: false },
    "completedSummary": { type: Object, required: false }
  },
  data: () => ({
    budgetOptions: [],
    forecast: {
      label: "Get up to",
      value: null,
      annotation: "deliverable IDs"
    },
    forecastLoading: false,
    customBudgetAmount: null,
    selectedOption: null,
    warning: {
      message: 'You are using a FREE version of the Universal Onboarding app. This limits the deliverable IDs you’ll receive to only 500,000  IDs every month. Upgrade your app subscription to remove this restriction',
      linkText: 'More Info',
      linkHref: ''
    }
  }),	
  watch: {
    selectedOption(val) {
      if (this.selectedOption !== null) {
        this.$emit('stepPayloadChanged', {
          budgetAmount: this.selectedOption.amount,
          idForecast: this.selectedOption.forecast,
          packageName: this.selectedOption.name
        })
      } else {
        this.$emit('stepPayloadChanged', null)
      }
    },
    matchData(val) {
      this.makeBudgetOptions()
    }
  },
  mounted() {
    this.makeBudgetOptions()
  },
  methods: {
    makeBudgetOptions() {
      const budgetOptions = []

      if (this.matchData && this.matchData.totalCost && this.matchData.numAddressable) {
        const starterCost = this.roundToNearest(0.1 * this.matchData.totalCost, 100)
        const standardCost = this.roundToNearest(0.5 * this.matchData.totalCost, 100)
        const totalCost = this.roundToNearest(this.matchData.totalCost, 100)

        budgetOptions.push({
          name: "Everything",
          amount: totalCost >= 200 ? totalCost : 100,
          forecast: this.roundToNearest(this.matchData.numAddressable, 100)
        })

        if (standardCost >= 100 && standardCost !== totalCost) {
          budgetOptions.unshift({
            name: "Standard",
            amount: standardCost,
            forecast: this.roundToNearest(0.5 * this.matchData.numAddressable, 100)
          })
        }

        if (starterCost >= 100 && starterCost !== standardCost && starterCost !== totalCost) {
          budgetOptions.unshift({
            name: "Starter",
            amount: starterCost,
            forecast: this.roundToNearest(0.1 * this.matchData.numAddressable, 100)
          })
        }
      }

      this.budgetOptions = budgetOptions
      this.initSelectedOption()
    },
    initSelectedOption() {
      if (this.budgetOptions.length > 1) {
        this.selectedOption = this.budgetOptions[1]
      } else if (this.budgetOptions.length) {
        this.selectedOption = this.budgetOptions[0]
      }
      if (this.selectedOption) {
        this.$emit('stepPayloadChanged', {
          budgetAmount: this.selectedOption.amount,
          idForecast: this.selectedOption.forecast,
          packageName: this.selectedOption.name
        })
      }
    },
    roundToNearest(number, interval) {
      return Math.round(number / interval) * interval; 
    },
    formatForecast(forecast) {
      return numeral(forecast).format('0,0')
    },
    getForecast(amount) {
      const ratio = amount / this.matchData.totalCost
      let forecastedMatches = this.roundToNearest(ratio.toFixed(2) * this.matchData.numAddressable, 100)
      
      this.budgetOptions.forEach(option => {
        if (amount >= option.amount && forecastedMatches < option.forecast) {
          forecastedMatches = option.forecast
        }
      })

      const everythingOption = this.budgetOptions.find(option => option.name === 'Everything')
      if (everythingOption && forecastedMatches > everythingOption.forecast) {
        forecastedMatches = everythingOption.forecast
      }

      this.forecast = {
        label: "Get up to",
        value: numeral(forecastedMatches).format('0,0'),
        annotation: "deliverable IDs"
      }
    },
    selectionChanged(val) {
      this.selectedOption = val
      if (this.selectedOption !== null) {
        this.$emit('stepPayloadChanged', {
          budgetAmount: this.selectedOption.amount,
          idForecast: this.selectedOption.forecast,
          packageName: this.selectedOption.name
        })
      }
    },
    customBudgetChanged(val) {
      this.forecast.value = null
      this.customBudgetAmount = val
      if (this.customBudgetValid) {
        this.getForecast(this.customBudgetAmount)
        this.$emit('stepPayloadChanged', {
          budgetAmount: this.customBudgetAmount,
          idForecast: this.forecast.value,
          packageName: 'Custom'
        })
      }
    },
    customBudgetOpened() {
      this.selectedOption = null
      this.forecast.value = null
    }, 
    customBudgetClosed() {
      this.initSelectedOption()
    },
    customBudgetValidChanged(val) {
      this.customBudgetValid = val
      if (!this.selectedOption && !this.customBudgetValid) {
        this.$emit('stepPayloadChanged', null)
      }
    }
  }
};
</script>

<style lang="sass" scoped>
  .budget-step
    .header
      margin-bottom: 24px
      flex-direction: column
      align-items: center
      h2
        margin-bottom: 8px
      p
        margin-bottom: 0px
</style>
