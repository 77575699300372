<template lang="pug">
  .new-onboarding-page
    .header.uo-header
      h1.nio-h1.text-primary-darker New Onboarding
      NioButton(caution-text @click="cancel") Cancel
    NioStepper(
      :ordered-steps="steps"
      :current-step="currentStep"
      :completed-steps="completedSteps"
      final-step-label="Activate Subscription"
      @nextStep="nextStep"
      @previousStep="previousStep"
      @submit="createSubscription"
      @stepSelected="stepSelected($event)"
    )
      NioStep(
        :valid="stepPayloads.source !== null"
        :summary="makeStepSummary('source')"
        step-name="source"
      )
        template(v-slot:content)
          SourceStep(
            :current-step="currentStep"
            :completed-summary="makeStepSummary('source')"
            @stepPayloadChanged="updatePayload('source', $event)"
            @setStepIncomplete="setStepIncomplete('source')"
          )
      NioStep(
        ref="match"
        step-name="match"
        :valid="stepPayloads.match !== null"
        :summary="makeStepSummary('match')"
      )
        template(v-slot:header-complete)
        template(v-slot:content) 
          MatchStep(
            :source-step-data="stepPayloads.source"
            :completed-summary="makeStepSummary('match')"
            @stepPayloadChanged="updatePayload('match', $event)"
            @deleteSource="deleteSource"
          )
      NioStep(
        :valid="stepPayloads.destination !== null"
        :summary="makeStepSummary('destination')"
        step-name="destination"
      )
        template(v-slot:header-complete)
        template(v-slot:content)
          DestinationStep(
            :completed-summary="makeStepSummary('destination')"
            @stepPayloadChanged="updatePayload('destination', $event)"
          )
      NioStep(
        :valid="stepPayloads.budget !== null"
        :summary="makeStepSummary('budget')"
        step-name="budget"
      )
        template(v-slot:header-complete)
        template(v-slot:content)
          BudgetStep(
            :match-data="stepPayloads.match"
            :completed-summary="makeStepSummary('budget')"
            @stepPayloadChanged="updatePayload('budget', $event)"
          )
      NioStep(
        :valid="stepPayloads.payment !== null"
        :summary="makeStepSummary('payment')"
        step-name="payment"
      )
        template(v-slot:header-complete)
        template(v-slot:content)
          PaymentStep(
            :completed-summary="makeStepSummary('payment')"
            @stepPayloadChanged="updatePayload('payment', $event)"
          )
      NioStep(
        :valid="stepPayloads.confirmation !== null"
        step-name="confirmation"
      )
        template(v-slot:header-complete)
        template(v-slot:content)
          .creating-subscription(v-if="loading")
            v-progress-circular.progress(
              size="80" 
              color="#1438F5"
              indeterminate 
            )
          ConfirmationStep(
            :budget-data="stepPayloads.budget"
            @stepPayloadChanged="updatePayload('confirmation', $event)"
          )
    NioDialog(
      v-model="cancelDialog" 
    )
      CancelDialog(
        @cancel="cancelDialog = false"
        @confirm="confirmCancellation"
      )
    NioDialog(
      v-model="errorDialog" 
    )
       ErrorDialog(
        @close="errorDialog = false"
      )
</template>

<script>

import BudgetStep from './steps/budget/BudgetStep'
import ConfirmationStep from './steps/confirmation/ConfirmationStep'
import DestinationStep from './steps/destination/DestinationStep'
import MatchStep from './steps/match/MatchStep'
import PaymentStep from './steps/payment/PaymentStep'
import SourceStep from './steps/source/SourceStep'
import CancelDialog from './CancelDialog'
import ErrorDialog from './ErrorDialog'
import numeral from 'numeral'
import moment from 'moment'

export default {
  components: { BudgetStep, ConfirmationStep, DestinationStep, MatchStep, PaymentStep, SourceStep, CancelDialog, ErrorDialog },
  data: () => ({
    steps: ['source', 'match', 'destination', 'budget', 'payment', 'confirmation'],
    currentStep: 'source',
    completedSteps: [],
    stepPayloads: {
      source: null,
      match: null,
      destination: null, 
      budget: null,
      payment: null,
      confirmation: null
    },
    loading: false,
    cancelDialog: false,
    errorDialog: false
  }),
  methods: {
    updatePayload(step, payload) {
      this.stepPayloads[step] = payload
      if (step === 'match') {
        this.refreshDestinationsPayload()
      }
    },
    refreshDestinationsPayload() {
      if (this.stepPayloads.match) {
        this.stepPayloads['destination'] = {
          imageSrc: "https://cdn.narrative.io/images/data-stream/images/destination-summary-tile.svg",
          title: 'Download from Narrative',
          detailsLabel: this.stepPayloads.match ? "Estimated Deliverable" : null,
          detailsValue: this.stepPayloads.match ? numeral(this.stepPayloads.match.numAddressable).format('0,0') : null,
          detailsAnnotation: this.stepPayloads.match ? `${(this.stepPayloads.match.totalDestinationIds / this.stepPayloads.match.numMatches).toFixed(1)} devices/ID` : null
        } 
      }  
    },
    nextStep() {
      this.sendGAData(this.currentStep)
      if (!this.completedSteps.includes(this.currentStep)) {
        this.completedSteps.push(this.currentStep)
      }
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) + 1]
      this.scrollToStep(this.steps.indexOf(this.currentStep))
    },
    sendGAData(stepComplete) {
      let params = {
        ecommerce: {
          items: []
        }
      }
      switch (stepComplete) {
        case 'source':
          break
        case 'match':
          params.event = 'add_to_cart'
          params.ecommerce.items.push({
            'item_name': this.stepPayloads.source.name,
            'item_id': this.stepPayloads.source.listId,
            'price': this.stepPayloads.match.totalCost / this.stepPayloads.match.numAddressable,
            'item_brand': 'Universal Onboarding',
            'item_variant': '',
            'item_list_name': 'UO App',
            'item_list_id': '',
            'index': 1,
            'quantity': this.stepPayloads.match.numAddressable
          })
          break
        case 'destination':
          params.event = 'begin_checkout'
          params.ecommerce.items.push({
            'item_name': this.stepPayloads.source.name,
            'item_id': this.stepPayloads.source.listId,
            'price': this.stepPayloads.match.totalCost / this.stepPayloads.match.numAddressable,
            'item_brand': 'Universal Onboarding',
            'item_variant': '',
            'item_list_name': 'UO App',
            'item_list_id': '',
            'index': 1,
            'quantity': this.stepPayloads.match.numAddressable
          })
          break
        case 'budget':
          params.event = 'add_shipping_info'
          params.ecommerce.items.push({
            'item_name': this.stepPayloads.source.name,
            'item_id': this.stepPayloads.source.listId,
            'price': this.stepPayloads.budget.budgetAmount / this.stepPayloads.budget.idForecast,
            'item_brand': 'Universal Onboarding',
            'item_variant': '',
            'item_list_name': 'UO App',
            'item_list_id': '',
            'index': 1,
            'quantity': this.stepPayloads.budget.idForecast
          })
          break
        case 'payment':
          params.event = 'add_payment_info'
          break
        default:
          break
      }
      if (params.event !== undefined) {
        dataLayer.push(params)
      }
    },
    previousStep() {
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) - 1]
      this.scrollToStep(this.steps.indexOf(this.currentStep))
    },
    stepSelected(stepName) {
      this.currentStep = stepName
    },
    deleteSource() {
      this.setStepIncomplete('source')
      this.currentStep = 'source'
    },
    setStepIncomplete(stepName) {
      const stepIndex = this.completedSteps.indexOf(stepName)
      this.completedSteps = this.completedSteps.filter((step, index) => {
        index < stepIndex
      })
      this.steps.map((step, index) => {
        if (index >= stepIndex) {
          this.stepPayloads[step] = null
        }
      })
    },
    stepComplete(stepName) {
      return this.completedSteps.includes(stepName)
    },
    makeStepSummary(stepName) {
      if (!this.stepPayloads[stepName]) {
        return
      }
      switch (stepName) {
        case 'source':
          return {
            imageSrc: "https://cdn.narrative.io/images/data-stream/images/narrative-placeholder-normal.svg",
            title: this.stepPayloads.source.name ? this.stepPayloads.source.name : null,
            detailsLabel: "Uniques",
            detailsValue: this.stepPayloads.source.numValidIds ? numeral(this.stepPayloads.source.numValidIds).format('0,0') : null
          }
        case 'match':
          return {
            imageSrc: "https://cdn.narrative.io/images/data-stream/images/narrative-placeholder-normal.svg",
            title: this.stepPayloads.source && this.stepPayloads.source.name ? `${this.stepPayloads.source.name} - onboarded ` : null,
            detailsLabel: "Estimated Matched",
            detailsValue: this.stepPayloads.match.numMatches ? numeral(this.stepPayloads.match.numMatches).format('0,0') : null,
            detailsAnnotation: this.stepPayloads.match.matchRate ? `${this.stepPayloads.match.matchRate}% match rate` : null
          }   
        case 'destination':
          return this.stepPayloads.destination
        case 'budget':
          return {
            imageSrc: "https://cdn.narrative.io/images/data-stream/images/budget-step-summary-tile.svg",
            title: `$${numeral(this.stepPayloads.budget.budgetAmount).format('0,0')} / monthly`,
            subtitle: this.stepPayloads.budget.packageName,
            detailsLabel: "Get up to",
            detailsValue: numeral(this.stepPayloads.budget.idForecast).format('0,0'),
            detailsAnnotation: "Deliverable IDs"
          }     
        case 'payment':
          return this.stepPayloads.payment  
        default:
          break;
      }
    },
    createSubscription() {
      let maxBid = 10.0
      this.loading = true
      let date = moment.utc()
      let name = `${this.stepPayloads.source.name}-${this.stepPayloads.source.listId}-Onboarded-${date.format('YYYYMMDDHHmmss')}`
      if (this.nioUser.contextCompany) {
        name = this.nioUser.contextCompany + '-' + name
      }
      let body = {
        name,
        budget: {
          monthly: {
            value: this.stepPayloads.budget.budgetAmount,
            currency: 'USD',
          }
        },
        description: `Order created using Universal Onboarding APP with list ${this.stepPayloads.source.name}.`,
        data_packages: [
          {
            id_mapping: {
              time_range: {
                from_inclusive: date.subtract(365, "days").format()
              },
              id_types: {
                left: ['md5_email', 'sha1_email', 'sha256_email'],
                right: ['adid', 'idfa']
              },
              list_constraint: {
                list_id: this.stepPayloads.source.listId,
                constraint: {
                  includes_id: true
                }
              },
              max_bid_price: {
                value: maxBid,
                currency: 'USD',
              }
            },
          }
        ]
      }
      let gaDataLayerParams = {
        event: 'purchase',
        ecommerce: {
          purchase: {
            'transaction_id': name,
            'affiliation': this.stepPayloads.budget.packageName,
            'value': body.budget.monthly.value,
            'tax': '0.00',
            'shipping': '0.00',
            'currency': body.budget.monthly.currency,
            items: [{
              'item_name': this.stepPayloads.source.name,
              'item_id': this.stepPayloads.source.listId,
              'price': this.stepPayloads.budget.budgetAmount / this.stepPayloads.budget.idForecast,
              'item_brand': 'Universal Onboarding',
              'item_variant': '',
              'item_list_name': 'UO App',
              'item_list_id': '',
              'index': 1,
              'quantity': this.stepPayloads.budget.idForecast
            }]
          }
        }
      }
      dataLayer.push(gaDataLayerParams)
      this.$nioOpenApi.post(
          '/subscriptions',
          body
        ).then(res => {
          const subscriptionId = res.data.id;
          return this.$nioOpenApi.put(`/subscriptions/${subscriptionId}/activate`)
        })
        .then(res => {
          parent.postMessage({
            name: 'pageNavigation',
            payload: 'subscriptions'
          },"*")
        })
        .catch(err => {
          this.errorDialog = true
          console.log('Error occurred : ', err)
        })
        .finally(() => {
          this.scrollToStep(0)
          this.loading = false
        })
    },
    cancel() {
      this.cancelDialog = true
    },
    confirmCancellation() {
      this.stepPayloads = {
        source: null,
        match: null,
        destination: null, 
        budget: null,
        payment: null,
        confirmation: null
      }
      this.completedSteps = []
      this.currentStep = 'source'
      this.cancelDialog = false
    },
    scrollToStep(stepIndex) { 
      this.$nextTick(() => {
        const top = 35 + stepIndex * 130
        parent.postMessage({
          name: 'scrollTo',
          payload: {
            x: 0,
            y: top
          }
        },"*")
      })       
    },
  }
};
</script>

<style lang="sass" scoped>
.new-onboarding-page
  .header
    display: flex
    justify-content: center
    align-items: flex-start
    position: relative
    margin-bottom: 32px
    .nio-button
      position: absolute
      right: 40px
  ::v-deep .nio-step-content-body
    position: relative
    .creating-subscription
      width: 100%
      height: 100%
      position: absolute
      .v-progress-circular
        position: relative
        left: 50%
        top: 100px
        margin-left: -2.5rem
        z-index: 2
</style>
