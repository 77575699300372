<template lang="pug">
  .upload-dialog(ref="dialog")
    .uo-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    h2.nio-h2.text-primary-darker Upload text file {{ chooserState }}
    NioFileChooser(
      v-model="file" 
      :state="chooserState"
      :percent-complete="percentComplete"
      :max-file-size="1024*1024*10"
      :validate-fn="() => true"
      :valid="fileValid"
      :success-msg="`Your file contains ${numIds} valid IDs and ${numErrors} errors. Name your list to continue.`"
      instructions="Upload a one-column .CSV or .TXT file of hashed or raw emails. <a>Learn more about accepted file formats.</a>"
      action-label="Hash and Upload"
      in-progress-msg="Hashing"
      error-msg="Your file contained no valid IDs"
      general-error-msg="Your file does not contain any valid IDs."
      @changed="loadTextFromFile($event)" 
      @actionClicked="execute"
      @cancelClicked="cancel" 
    )
      template(v-slot:instructions)
        span Upload a one-column .CSV or .TXT file of hashed or raw emails.
        a(
          href="https://kb.narrative.io/what-file-formats-are-supported-in-universal-onboarding" 
          target="_blank" style="display: block;"
        ) Learn more about accepted file formats
      template(v-slot:success-actions)        
        NioTextField.list-name(
          v-model="listName"
          solo
          placeholder="List name"
        )
      template(v-slot:error-actions)        
        NioButton(
          normal-primary 
          @click="reset"
        ) Start Over		
    NioAlert(
      :message-title="warningMessageTitle"
      :message="warningMessage" 
      :dismissable="false"
      warning 
    )  
    .actions
      NioButton(normal-secondary @click="close") cancel
      NioButton(
        :disabled="!listValid"
        normal-primary 
        @click="complete"
      ) continue
    NioDialog(
      v-model="uploadingDialog" 
    )
      UploadingDialog(
        @cancel="uploadingDialog = false"
      )
    NioDialog(
      v-model="errorDialog" 
    )
      ErrorDialog(
        @close="errorDialog = false"
      )
    NioDialog(
      v-model="minSizeErrorDialog" 
    )
      ErrorDialog(
        heading="Not enough data"
        description="Sorry, this list is too small. Please upload a list of at least 5,000 IDs."
        @close="minSizeErrorDialog = false"
      )
    NioDialog(
      v-model="maxFilesizeDialog" 
    )
      MaxFilesizeDialog(
        @pickExistingList="pickExistingList"
      )  
</template>

<script>

import * as fs from '@narrative.io/native-file-system-adapter'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import UploadingDialog from './UploadingDialog'
import ErrorDialog from '@/shared/components/ErrorDialog'
import MaxFilesizeDialog from './MaxFilesizeDialog'

export default {
  components: { UploadingDialog, ErrorDialog, MaxFilesizeDialog },
  data: () => ({
    loading: false,
    file: null,
    chooserState: 'initial',
    valid: true,
    percentComplete: 0,
    selectedSettings: ["md5", "sha1", "sha256"],
    writableFileHandle: null,
    completeFile: null,
    numIds: 0,
    readStream: null,
    writeStream: null,
    writer: null,
    numErrors: 0,
    fileValid: true,
    listValid: false,
    errorDialog: false,
    listName: null,
    uploadingDialog: false,
    warningMessageTitle: 'The security of your data comes first. ',
    warningMessage: 'Before uploading your data to Narrative’s servers, your data will be hashed from your computer, locally, to keep your data safe from prying eyes. ',
    root: null,
    minSizeErrorDialog: false,
    maxFilesizeDialog: false
  }),
  watch: {
    listName(val) {
      this.listValid = this.completeFile && val !== null && val.length > 0 ? true : false
    }
  },
  mounted() {
    const {
      showDirectoryPicker,
      showOpenFilePicker,
      showSaveFilePicker,
      FileSystemFileHandle,
      FileSystemHandle,
      FileSystemWritableFileStream,
    } = fs
    globalThis.fs = fs
    this.getRoot()
  },
  methods: {
    async getRoot() {
      this.root = await navigator.storage.getDirectory()
    },
    close() {
      this.cancel()
      this.$emit('close')
    },
    setState(val) {
      this.chooserState = val
    },
    updateProgress(val) {
      this.percentComplete = val
    },
    loadTextFromFile(files) {
      this.file = files[0]
    },
    execute() {
      this.createWorker()
      this.doWork(this.worker, this.file)
    },
    createWorker() {   
      this.worker = new Worker("./worker.js", {
        type: "module",
      });
    },
    cancel() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      if (this.writer) {
        this.writer.abort()
      }
      this.reset()
    },
    
    reset() {
      this.chooserState = 'initial'
      this.percentComplete = 0
      this.completeFile = null
    },
    async makeWritableFileHandle() {
      return await this.root.getFileHandle('Untitled.txt', { create: true })
    },
    async writeChunk(chunk) {
      await this.writer.write(chunk)
    },
    async closeWriter() {
      this.percentComplete = 0
      await this.writer.close()
    },
    async getCompleteFile() {      
      await this.writer.close()
      const fileHandle = await this.root.getFileHandle('Untitled.txt')
      const options = {mode: 'readwrite'}
      const permissions = await fileHandle.requestPermission(options)
      const file = await fileHandle.getFile()
      // const text = await file.text()
      // console.log(text)
      this.completeFile = file
    },
    async doWork(worker, data) {
      this.chooserState = 'selected'
      this.writableFileHandle = await this.makeWritableFileHandle()
      this.writer = await this.writableFileHandle.createWritable()
      this.chooserState = 'inProgress'
      return new Promise((resolve, reject) => {
        this.worker.addEventListener("message", (e) => {
          switch (e.data.cmd) {
            case "preview":
              break;
            case "returned":
              this.writeChunk(e.data.payload)
              resolve(new Blob([e.data.payload]));
              break;
            case "completed":
              this.numIds = e.data.payload.numIds
              this.numErrors = e.data.payload.numErrors
              if (this.numIds > 0) {
                if (this.numIds < 5000) {
                  this.minSizeErrorDialog = true
                  this.reset()
                } else {
                  this.getCompleteFile()
                  this.chooserState = 'success'
                }
              } else {
                this.chooserState = 'error'
              }
              break;
            case "error": 
              console.error("something went wrong", e.data.cmd);
              reject("Main page doesn't understand command " + e.data.cmd);
              break;
            case "empty":
              resolve(new Blob([]));
              break;
            case "updateProgress":
              this.updateProgress(e.data.payload)
              break;
            default:
              console.error("something went wrong", e.data.cmd);
              reject("Main page doesn't understand command " + e.data.cmd);
              break;
          }
        });

        data.arrayBuffer().then((arrBuff) => {
          this.worker.postMessage({ 
            cmd: "start", 
            payload: arrBuff, 
            transformerArgs: {
              size: data.size
            },
            settings: this.selectedSettings
            }, [arrBuff]
          );
        });
      });
    },
    terminateWorker() {
      this.worker.terminate()
    },
    uploadFile() {
      this.loading = true
      const path = uuidv4()
      return new Promise((resolve, reject) => {
        this.$nioOpenApi.post(`/uploads/${path}`).then(res => {
          if (res.status === 200) {
            const uploadUrl = res.data.url
            this.loading = false
            this.uploadingDialog = true
            axios.put(uploadUrl, this.completeFile).then(uploadRes => {
              if (uploadRes.status === 200) {
                const listRequest = {
                  "name": this.listName, 
                  "description":"", 
                  "source_file": path
                }
                this.$nioOpenApi.post('/lists', listRequest).then(listRes => {
                  if (listRes.status === 200) {
                    this.uploadingDialog = false
                    this.$nextTick(() => {
                      this.$emit('complete', {
                        listId: listRes.data.id,
                        name: listRes.data.name,
                        numValidIds: this.numIds,
                      })
                    })  
                    this.reset()
                  } else {
                    this.error()
                  }	
                })
                if (this.numIds > 900000) {
                  this.uploadingDialog = false
                  this.$nextTick(() => {
                    this.maxFilesizeDialog = true
                  })
                } 
              } else {
                this.error()
              }	
            }).catch(err => {
              this.error()
            })
          } else {
            this.error()
          }  
        }).catch(err => {
          this.error()
        })
      }).catch(err => {
        this.error()
      })
    },
    
    error() {
      this.reset()
      this.loading = false
      this.uploadingDialog = false
      this.errorDialog = true
    },
    async complete() {
      const listId = await this.uploadFile()
      if (listId) {
        parent.postMessage({
        name: 'scrollTo',
          payload: {
            x: 0,
            y: 0
          }
        },"*")        
      } else {
        // handle error
      }
    },
    pickExistingList() {
      this.maxFilesizeDialog = false
      this.$nextTick(() => {
        this.$emit('pickExistingList')
      })  
    }
  }
};
</script>

<style lang="sass" scoped>
  .upload-dialog
    h2, .nio-file-chooser
      margin-bottom: 24px  
    .actions
      display: flex
      justify-content: flex-end
      & > .nio-button + .nio-button
        margin-left: 16px

    ::v-deep .list-name
      width: 360px    
    ::v-deep .nio-file-chooser:not(.state-initial) .spacer
      margin-top: 0px
    ::v-deep .nio-file-chooser.state-success .spacer
      margin-bottom: 0px
</style>