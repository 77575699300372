<template lang="pug">
  .uploading-dialog(ref="dialog")
    v-progress-circular.progress(
      size="80" 
      color="#1438F5"
      indeterminate 
    )
    h3.nio-h3.text-primary-darker Uploading your file
    p.nio-p.text-primary-dark This could take awhile depending on the size of the file.
    .actions
      NioButton(
        normal-secondary 
        @click="cancel"
      ) cancel
</template>

<script>


export default {
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.uploading-dialog
  padding: 100px 24px 78px 24px
  background-color: $c-white
  border: 1px solid $c-primary-lighter
  border-radius: 12px
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  .nio-icon-framer
    margin-bottom: 16px
  h3
    margin-bottom: 8px
    margin-top: 80px
  p    
    margin-bottom: 40px
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + * 
      margin-left: 16px  

  .v-progress-circular
    position: absolute
    top: 52px
    z-index: 2		
</style>