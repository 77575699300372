<template lang="pug">
  .payment-step
    .header
      h2.nio-h2.text-primary-darker Choose your payment method
    NioChoosePaymentMethod(
      @paymentMethodChanged="paymentMethodChanged($event)"
    )
</template>

<script>


export default {
  methods: {
    paymentMethodChanged(payload) {
      this.$emit('stepPayloadChanged', payload)
    }
  }
};
</script>

<style lang="sass" scoped>
  .payment-step
    display: flex
    flex-direction: column
    .header
      display: flex
      justify-content: center
      align-items: flex-start
      position: relative
      margin-bottom: 32px
</style>