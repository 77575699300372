<template lang="pug">
  .cancel-new-onboarding-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-warning"
    )
    h3.nio-h3.text-primary-darker You are about to cancel onboarding
    p.nio-p.text-primary-dark This cannot be undone and you will lose unsaved changes. Are you sure?
    .actions
      NioButton(
        normal-secondary
        @click="cancel"
      ) No, don't cancel
      NioButton(
        caution-outlined
        @click="confirm"
      ) Yes, cancel onboarding
</template>

<script>


export default {
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'quick-start'
      },"*")
      this.$emit('confirm')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.cancel-new-onboarding-dialog
  padding: 100px 24px 78px 24px
  background-color: $c-white
  border: 1px solid $c-primary-lighter
  border-radius: 12px
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 16px
  h3
    margin-bottom: 8px
  p    
    margin-bottom: 40px
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + * 
      margin-left: 16px  
</style>