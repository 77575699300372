<template lang="pug">
  .source-step
    .uo-header.header
      h2.nio-h2.text-primary-darker 
        span(v-if="!completedSummary") To get started, choose a data source
        span(v-if="completedSummary") You've chosen to onboard
    NioOptionsGrid(
      v-if="!completedSummary && !chooseListDialog && !uploadDialog"
    )
      NioSourceOption(
        v-for="option of sourceOptions"
        :option="option"
        :selected="option && selectedSourceOption && selectedSourceOption.name === option.name"
        @selected="sourceSelectionChanged($event)"
      ) 
    NioDialog(
      v-model="chooseListDialog" 
    )
      ChooseListDialog(
        @complete="complete($event)"
        @close="chooseListDialog = false"
      )
    NioDialog(
      v-model="uploadDialog" 
    )
      UploadDialog(
        @complete="complete($event)"
        @close="uploadDialog = false"
        @pickExistingList="pickExistingList"
      )
    NioDialog(
      v-model="deleteSourceDialog" 
    )
      DeleteSourceDialog(
        @confirm="deleteSource($event)"
        @cancel="deleteSourceDialog = false"
      )  
    .summary(v-if="completedSummary && !chooseListDialog")
      .summary-left
      NioSummarySlat(
        :imageSrc="completedSummary.imageSrc"
        :title="completedSummary.title"
        :detailsLabel="completedSummary.detailsLabel"
        :detailsValue="completedSummary.detailsValue"
        :detailsAnnotation="completedSummary.detailsAnnotation"
        active
      )  
      .summary-right
        NioButton(
          icon-name="utility-trash"
          icon-color="#415298"
          normal-icon 
          @click="deleteSourceDialog = true"
        )
</template>

<script>

import UploadDialog from './UploadDialog'
import ChooseListDialog from './ChooseListDialog'
import DeleteSourceDialog from './DeleteSourceDialog'

export default {
  components: { UploadDialog, ChooseListDialog, DeleteSourceDialog },
  props: {
    "completedSummary": { type: Object, required: false },
    "currentStep": { type: String, required: false }
  },
  data: () => ({
    sourceOptions: [
      {
        name: 'upload',
        title: 'Upload a File',
        description: 'Select a .CSV or .TXT file with hashed or raw emails.',
        iconName: 'display-listmanager'
      },  
      {
        name: 'existing',
        title: 'Pick an existing list',
        description: 'Choose a list of users to onboard.',
        iconName: 'display-upload'
      },
      {
        name: 'new',
        title: 'Setup a new source',
        description: 'Log in to your source or platform to import data.',
        iconName: 'display-new',
        status: 'coming'
      },
    ],  
    selectedSourceOption: null,
    uploadDialog: false,
    chooseListDialog: false,
    deleteSourceDialog: false
  }),	
  watch: {
    currentStep() {
      if (this.currentStep !== 'source') {
        this.chooseListDialog = false
      }
    }
  },
  methods: {
    sourceSelectionChanged(option) {
      if (option && option.name === 'existing') {
        this.chooseListDialog = true
      } else if (option && option.name === 'upload') {
        this.uploadDialog = true
      }
    },
    complete(list) {
      this.uploadDialog = false
      this.$nextTick(() => {
        this.$emit('stepPayloadChanged', list)
      })
    },
    deleteSource() {
      this.chooseListDialog = false
      this.deleteSourceDialog = false
      this.$emit('setStepIncomplete')
      this.$emit('stepPayloadChanged', null)
    },
    pickExistingList() {
      this.uploadDialog =  false
      this.$nextTick(() => {
        this.chooseListDialog = true
      })
    }
  }
};
</script>f

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"
.source-step
  .header
    margin-bottom: 24px
  .summary
    box-shadow: none
    display: flex
    justify-content: space-between
    align-items: center
    .summary-left, .summary-right
      flex-basis: 0
      flex-grow: 2
    .nio-summary-slat
      margin-right: 1.5rem
    .summary-right
      margin-right: 0rem
      margin-left: 1.5rem
      display: flex
      justify-content: center
      align-items: center   
      ::v-deep svg
        color: $c-primary-dark
        margin-right: 1px
</style>