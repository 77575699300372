<template lang="pug">
  .choose-list-dialog(ref="dialog")
    .uo-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .header.uo-header
      h1.nio-h1.text-primary-darker List Selector
      NioButton(
        caution-text 
        @click="cancel"
      ) Back
    .spacer(v-if="loading")  
    .lists
      NioSlatTable(
        v-if="columns && lists"
        :items="lists"
        :columns="columns"
        :sort-options="sortOptions"
        :initial-items-per-page="10"
        :searchable-props="['name', 'id']"
        single-select
        pagination
        search-sort-header
        @selectionChanged="selectionChanged($event)"
      )
    NioDivider(horizontal-solo)
    .actions
      NioButton(
        normal-secondary
        @click="cancel"
      ) Cancel
      NioButton(
        :disabled="selection === null"
        normal-primary
        @click="complete"
      ) Continue
</template>

<script>

import { mockLists } from './mockLists'
import { sortOptions } from './sortOptions'
import numeral from 'numeral'
import { formatDate } from '@/plugins/date'
import moment from 'moment'
import { NioOpenApiModule } from '@narrative.io/tackle-box'

export default {
  data: () => ({
    columns: null,
    sortOptions: sortOptions,
    selection: null,
    lists: null,
    loading: true
  }),	
  watch: {
    lists() {
      this.makeColumns()
    }
  },
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    openApiInit() {
      this.getLists().then(lists => {
        this.loading = false
        this.lists = lists
        if (this.lists && this.lists.length) {
          this.makeColumns()
        }
      })
    },
    getLists() {
      return new Promise((resolve, reject) => {
        this.$nioOpenApi.get('/lists?schema=id&status=active').then(res => {
          resolve(res.data.records)
        })
      }) 
    },
    selectionChanged(val) {
      this.selection = val
      this.$emit('complete', {
        listId: this.selection.id,
        name: this.selection.name,
        numValidIds: this.selection.count,
      })
    },
    computeListId(item) {
      return `#${item.id}`
    },
    computeUniques(item) {
      return numeral(item.count).format('0,0')
    },
    computeCreated(item) {
      return moment(item.created_at).format('MMM D, YYYY')
    },
    computeImage() {
      return 'https://cdn.narrative.io/images/data-stream/images/narrative-placeholder-normal.svg'
    },
    makeColumns() {
      this.columns = [
        {
          name: "slat",
          props: {
            image: this.computeImage,
            title: "name",
            subtitle: this.computeListId
          }
        },
        {
          name: "uniques",
          label: "Uniques",
          computed: this.computeUniques
        },
        {
          name: "created",
          label: "Created",
          computed: this.computeCreated
        }
      ]  
    },
    upgrade() {
      window.open('https://app.narrative.io/app/universal-onboarding', '_blank')
    },
    cancel() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      this.$emit('complete', null)
      this.$emit('close')
    },
    complete() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      this.$emit('complete', {
        listId: this.selection.id,
        name: this.selection.name,
        numValidIds: this.selection.count,
      })
      this.$emit('close')
    }
  }
};
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.choose-list-dialog
  background-color: $c-white
  .header
    display: flex
    justify-content: space-between
    align-items: flex-end
    position: relative
    margin-bottom: 24px
    h1
      line-height: 1.75rem
  .lists
    margin-bottom: 24px
  .actions
    margin-top: 24px
    display: flex
    justify-content: flex-end
    & > * + * 
      margin-left: 16px   
  .spacer
    min-height: 400px
</style>