export const sortOptions = [
  {
		label: 'ID number: High to Low',
		value: {
			itemProp: 'id',
			propType: 'Number',
			order: 'descending'
		}
	},
	{
		label: 'ID number: Low to High',
		value: {
			itemProp: 'id',
			propType: 'Number',
			order: 'asscending'
		}	
  },
  {
    label: 'List Name: A-Z',
    value: {
			itemProp: 'name',
			propType: 'String',
			order: 'ascending'
		}	
  },
  {
		label: 'List Name: Z-A',
		value: {
			itemProp: 'name',
			propType: 'String',
			order: 'descending'
		}	
	},	
  {
		label: 'Date Created: New to Old',
		value: {
			itemProp: 'created_at',
			propType: 'Date',
			order: 'descending'
		}	
	},
	{
		label: 'Date Created: Old to New',
		value: {
			itemProp: 'created_at',
			propType: 'Date',
			order: 'ascending'
		}
	}
]