function randomDate(start, end, startHour, endHour) {
  var date = new Date(+start + Math.random() * (end - start));
  var hour = startHour + Math.random() * (endHour - startHour) | 0;
  date.setHours(hour);
  return date;
}

export const mockLists = [
	{
		count: 898300,
		created_at: "04/03/2020 19:33:39",
		id: 211,
		name: "Sample_Female 35-44 - Low - Narrative"
	}	
	// {
	// 	id: 1,
	// 	listId: 301,
	// 	name: "My list and stuff",
	// 	idTypes: "MAIDs", 
	// 	uniques: 10034511,
	// 	created: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
	// },
	// {
	// 	id: 2,
	// 	listId: 317,
	// 	name: "Another list",
	// 	idTypes: "MAIDs", 
	// 	uniques: 12034511,
	// 	created: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
	// },
	// {
	// 	id: 3,
	// 	listId: 1001,
	// 	name: "Another cool list name",
	// 	idTypes: "MAIDs", 
	// 	uniques: 20034511,
	// 	created: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
	// },
	// {
	// 	id: 4,
	// 	listId: 250,
	// 	name: "A test list",
	// 	idTypes: "MAIDs", 
	// 	uniques: 3034211,
	// 	created: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
	// },
	// {
	// 	id: 5,
	// 	listId: 599,
	// 	name: "Random",
	// 	idTypes: "MAIDs", 
	// 	uniques: 23031511,
	// 	created: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
	// },
	// {
	// 	id: 6,
	// 	listId: 1452,
	// 	name: "Sandwich",
	// 	idTypes: "MAIDs", 
	// 	uniques: 1499900,
	// 	created: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
	// },
	// {
	// 	id: 7,
	// 	listId: 333,
	// 	name: "Stuff",
	// 	idTypes: "MAIDs", 
	// 	uniques: 12034511,
	// 	created: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
	// },
	// {
	// 	id: 8,
	// 	listId: 9,
	// 	name: "Starbucks",
	// 	idTypes: "MAIDs", 
	// 	uniques: 4500,
	// 	created: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
	// },
	// {
	// 	id: 9,
	// 	listId: 41,
	// 	name: "My list and stuff",
	// 	idTypes: "MAIDs", 
	// 	uniques: 10034511,
	// 	created: randomDate(new Date(2020, 0, 1), new Date(), 0, 24)
	// }
]